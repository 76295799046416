import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Fab, TextField, TextareaAutosize, Grid } from '@material-ui/core'
import { ArrowBack, GetApp, Print, Visibility } from '@material-ui/icons'
import { Link } from "react-router-dom";

const QrCodeScanner = (props) => {
  const [data, setData] = useState('No result');

  return (
    <>
      <Link to="/">
        <Fab style={{ marginRight: 10 }} color="secondary">
          <ArrowBack />
        </Fab>
      </Link>
      <span><strong>QR code scanner</strong></span>
      <Grid container>
        <Grid item xs>
        </Grid>
        <Grid item xs={6}>
          <QrReader onResult={(result, error) => {
            if (!!result) {
              setData(result?.text);
            }

            if (!!error) {
              console.info(error);
            }
          }}
            style={{ width: '100%' }}
            constraints={{
              facingMode: 'environment'
          }}
          />
          <p>{data}</p>
        </Grid>
        <Grid item xs>
        </Grid>
      </Grid>

    </>
  );
};

export default QrCodeScanner;