import { Grid, Button, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import './Home.scss'
function Home() {

    return (
        <div>

            <Typography style={{ margin: 20 }} variant="h4">
                Fulcrum One Barcode
            </Typography>

            <Grid container>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={6} sm={2} className='mb-3'>
                <Typography style={{ margin: 20 }} variant="h5">
                Barcode Generator
            </Typography>
                    <Link to="/barcode_generator">
                        <Button variant="contained" size="small" color="secondary" className='barCodeIcons'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" style={{padding:30}} fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
</svg>
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={6} sm={2} className='mb-3'>
                <Typography style={{ margin: 20 }} variant="h5">
                 Barcode  Scanner
            </Typography>
                    {/* <Link to="/barcode_scanner"> */}
                    <Link to="/capture_image">
                        <Button variant="contained" size="small" color="secondary" className='barCodeIcons'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" style={{ padding: 30 }} fill="currentColor" class="bi bi-upc" viewBox="0 0 16 16">
                                <path d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                            </svg>
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={6} sm={2}>
                <Typography style={{ margin: 20 }} variant="h5">
                2D-Barcode Scanner
            </Typography>
                    <Link to="/2d_barcode_scanner">
                        <Button variant="contained" size="small" color="secondary" className='barCodeIcons'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" style={{ padding: 30 }} fill="currentColor" class="bi bi-upc" viewBox="0 0 16 16">
                                <path d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                            </svg>
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
            </Grid>
            <Typography variant="h4" style={{ margin: 20 }}>
            Fulcrum One QR code
            </Typography>
            <Grid container>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={6} sm={3}>
                    <Link to="/qrcode_generator">
                        <Button variant="contained" size="large" color="secondary" className='barCodeIcons'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" style={{padding:30}} fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
</svg>
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Link to="/qrcode_scanner">
                        <Button variant="contained" size="large" color="secondary" className='barCodeIcons'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" style={{ padding: 30 }} fill="currentColor" class="bi bi-qr-code-scan" viewBox="0 0 16 16">
  <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0v-3Zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5ZM.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5Zm15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5ZM4 4h1v1H4V4Z"></path>
  <path d="M7 2H2v5h5V2ZM3 3h3v3H3V3Zm2 8H4v1h1v-1Z"></path>
  <path d="M7 9H2v5h5V9Zm-4 1h3v3H3v-3Zm8-6h1v1h-1V4Z"></path>
  <path d="M9 2h5v5H9V2Zm1 1v3h3V3h-3ZM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8H8Zm2 2H9V9h1v1Zm4 2h-1v1h-2v1h3v-2Zm-4 2v-1H8v1h2Z"></path>
  <path d="M12 9h2V8h-2v1Z"></path>
</svg>
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
            </Grid>
        </div>
    );
}

export default Home;
