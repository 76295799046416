import React from 'react';
import { Route, Redirect } from "react-router-dom";




const isAuthenticated=()=>{
    return localStorage.getItem("token") ? true : false;
}

export const PublicRoute = ({ component: Component, restricted, path, ...rest }) => {
  console.log(isAuthenticated(),"isauth");
    return (
      <Route {...rest} render={props => {
        if (isAuthenticated() && path === '/') {
          return <Redirect to={{ pathname:'/home'}} />
        }
        return <Component {...props} />
    }} />
    );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  console.log(isAuthenticated(),"isauthprivate");
    return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page.!
      <Route {...rest} render={props => {
        if (!isAuthenticated()) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/'}} />
        }
        return <Component {...props} />
    }} />
    );
  };


