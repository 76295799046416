import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Fab, TextField, TextareaAutosize, Grid } from '@material-ui/core';
import { ArrowBack, GetApp, Print, Visibility } from '@material-ui/icons';
import { Link } from "react-router-dom";

const QRCodeView = () => {

    const [qrcode, setqrcode] = useState('123456789012');
    const handleChange = (event) => {
            let inputValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
            setqrcode(event.target.value ? inputValue : '');
    };

    const downloadqrcode = () => {
        const canvas = document.getElementById("qrcode");

        // const pngUrl = canvas
        //   .toDataURL("image/png")
        //   .replace("image/png", "image/octet-stream");

        canvas.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        var svgData = canvas.outerHTML;
        var preface = '<?xml version="1.0" standalone="no"?>\r\n';
        var svgBlob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
        var svgUrl = URL.createObjectURL(svgBlob);
        let downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "myqrcode.svg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const printqrcode = () => {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');
        mywindow.document.write(document.getElementById("qrcodeToPrint").innerHTML);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        setTimeout(() => {
            mywindow.print();
            mywindow.close();
        }, 100)
        return true;
    }
    return (
        <div>
            <Link to="/">
                <Fab style={{ marginRight: 20 }} color="secondary">
                    <ArrowBack />
                </Fab>
            </Link>
            <span>QR code Generator</span>
           
           
            <div style={{marginTop:30, marginBottom:30}}>
                <TextField onChange={handleChange} style={{width:320}}
                value={qrcode} label="qrcode input" size="large" variant="outlined"  inputProps={{ maxlength: 12 }}
                />
            </div>
            <div>
                
                {
                    qrcode != "" ? <div id="qrcodeToPrint"> <QRCode value={qrcode} id="qrcode" /></div> : <p>No Preview</p>
                }
            </div>
            {qrcode?
         <div style={{textAlign:'center'}}>
         <TextareaAutosize
                 style={{fontSize:18,width:"80%",height:100}}
                 rowsMax={4}
                 defaultValue={qrcode}
                 value={qrcode}
             />
                <div >
                            <Fab onClick={downloadqrcode} style={{marginLeft:10}} color="secondary" id="elem">
                            <GetApp/>
                        </Fab>
                        <Fab onClick={(e,elem)=>printqrcode(e,elem)} style={{marginLeft:10}} color="secondary">
                            <Print/>
                        </Fab>
                    </div>
         </div >:""    
        }
                  
        </div>
    )
}

export default QRCodeView