import Webcam from "react-webcam";
import { useCallback,useRef, useState } from "react";
import axios from 'axios';
import { Fab, TextareaAutosize, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
const ImageScan = () => {
  const webcamRef = useRef(null); // create a webcam reference
  const [imgSrc, setImgSrc] = useState(null);
  const [barcodeNo, setBarcodeNo] =useState(null)

  const capture = useCallback( async() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    console.log(imageSrc)
         
var result = imageSrc.substr(imageSrc.indexOf(",") + 1);
console.log(result);
    const response = await axios.post("https://barcode-fulcrumoneflask.demo.fulcrumone.net/processCode",{"base64":result})
    console.log(response,"server");
setBarcodeNo(response.data.scannedCode)
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
    setBarcodeNo(null)
  };
  const videoConstraints = {
    facingMode: 'environment', // Use the rear camera
  };

  return (
    <div className="container">
          <Link to="/" style={{display:"inline-block", margin:"0 auto"}}>
            <Fab style={{ marginRight: 20 }} color="secondary">
              <ArrowBack />
            </Fab>
            <span>
            <strong>Barcode Scanner</strong>
          </span>
          </Link>
          <div className="clearfix"></div>

      {imgSrc ? (
        <img src={imgSrc} alt="webcam" />
      ) : (
        <Webcam
        videoConstraints={videoConstraints}
        height={500} width={600} ref={webcamRef} />
      )}
      <div className="btn-container">
        {imgSrc ? (
          <button onClick={retake}>Retake barcode</button>
        ) : (
          <button onClick={capture}>Capture barcode</button>
        )}
      </div>
      <TextareaAutosize
        style={{ fontSize: 32, width: 320, height: 100, marginTop: 30 }}
        rowsMax={4}
        defaultValue={'No data scanned'}
        value={barcodeNo ? barcodeNo : 'No data scanned'}
      />
    </div>
  );
};

export default ImageScan;