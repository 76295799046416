import React, { Component } from 'react'
import Quagga from 'quagga'

class Scanner extends Component {
  componentDidMount() {
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: 640,
            height: 320,
            facingMode: 'environment',
          },
        //   area: { // defines rectangle of the detection/localization area
        //     top: "10%",    // top offset
        //     right: "10%",  // right offset
        //     left: "10%",   // left offset
        //     bottom: "10%"  // bottom offset
        //   },
        },
        locator: {
            halfSample: true,
            patchSize: "large", // x-small, small, medium, large, x-large
            debug: {
                showCanvas: true,
                showPatches: false,
                showFoundPatches: false,
                showSkeleton: false,
                showLabels: false,
                showPatchLabels: false,
                showRemainingPatchLabels: false,
                boxFromPatches: {
                    showTransformed: true,
                    showTransformedBox: true,
                    showBB: true
              }
            }
        },
        numOfWorkers: 4,
        decoder: {
          readers: [ 'upc_reader','code_128_reader', 'ean_reader','upc_e_reader'],
          //readers: ['code_128_reader', 'ean_reader', 'upc_reader'],
        //   readers: ['code_128_reader', 'upc_reader', 'itf_14_reader',
        //   {
        //     format: "ean_reader",
        //     config: {
        //         supplements: [
        //             'ean_5_reader', 'ean_2_reader'
        //         ]
        //     }
        // }],
        //   //readers: ['code_128_reader', 'ean_reader', 'upc_reader'],
            debug: {
                drawBoundingBox: true,
                showFrequency: true,
                drawScanline: true,
                showPattern: true
            },
        },
        locate: true,
      },
      function(err) {
        if (err) {
          return console.log(err)
        }
        Quagga.start()
      },
    )
    Quagga.onDetected(this._onDetected)
   
    }
    else{
      console.log('getUserMedia is not supported on this device/browser.');
    }
  }

  componentWillUnmount() {
    Quagga.offDetected(this._onDetected)
  }

  _onDetected = result => {
    this.props.onDetected(result)
  }

  render() {
    return <div id="interactive" className="viewport"/>
    }
}

export default Scanner
