import React, {useEffect, useState, useRef} from 'react';
import { Fab, TextareaAutosize, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

const BarcodeReader= () => {
    const textInputRef = useRef(null);
    const focusTextInput = () => {
        textInputRef.current.focus();
      };
      useEffect(() => {
        focusTextInput();
      }, []); //

 
        
  return (
    <div className="container">
         <Link to="/" style={{display:"inline-block", marginBottom:'2rem'}}>
            <Fab style={{ marginRight: 20 }} color="secondary">
              <ArrowBack />
            </Fab>
            <span>
            <strong>Barcode Scanner</strong>
          </span>
          </Link>
          <div className='clearfix'></div>
          <label>
          Barcode Number:
          </label>
          <div className='clearfix'></div>
          <input type="text" ref={textInputRef} name="barcode"/>
        
    </div>
  );
};

export default BarcodeReader;