import React, { useEffect, useState } from 'react';
import './login.scss'; // Import the CSS file
import { useHistory } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { SERVICE_TOKEN } from '../util/config';
function LoginForm() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [applicationTenantLinkId,setApplicationTenantLinkId] = useState(null)
  const [applicationKey, setApplicationKey] = useState('')
  const [tokenId,setTokenId] = useState('')
  const [tokenKey,setTokenKey] =useState()
  const [tenantKey,setTenantKey] = useState('')

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  useEffect(()=>{
getServiceToken()
  },[])

  const getServiceToken = () => {
    axios
      .post(
        "https://csdev.culinarysuite.com:65500/api/serviceToken/detail",
        SERVICE_TOKEN,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        // Access response data here

        setApplicationKey(response.data.serviceToken.applicationKey)
        setApplicationTenantLinkId(response.data.serviceToken.applicationTenantLinkId)
        setTenantKey(response.data.serviceToken.tenantKey)
        setTokenId(response.data.serviceToken.tokenId)
        setTokenKey(response.data.serviceToken.tokenKey)
        console.log(response.data.serviceToken.applicationKey);
      })
      .catch(function (error) {
        // Handle errors here
        console.error("Error:", error);
      });
  };




  const validateInput = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      // case 'email':
      //   const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      //   newErrors.email = !emailPattern.test(value) ? 'Invalid email address' : '';
      //   break;
      case 'password':
        newErrors.password = value.length < 6 ? 'Password must be at least 6 characters' : '';
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email && password && !errors.email && !errors.password) {
      // Perform your login logic here

localStorage.setItem('token',tokenKey)

var myHeaders = new Headers();
myHeaders.append("Access-Application", applicationKey);
myHeaders.append("Client-Token", tenantKey);
myHeaders.append("ApplicationTenantLinkId", applicationTenantLinkId);
myHeaders.append("WebAccess-Token", tokenKey);
myHeaders.append("WebAccess-TokenId", tokenId);
myHeaders.append("IsExpirationCheckOptional", "true");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "userName": email,
  "password": password,
  "memberTypeId": "2",
  "MEMBERID": 1
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://csdev.culinarysuite.com:65500/api/user/AuthenticationRequest", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
      history.push('/home')
      console.log('Logging in with email: ', email, ' and password: ', password);
    } else {
      alert('Please fix the errors in the form');
    }
  };


  return (
    //  redirect === "true" ? (
    //   <Redirect to={{ pathname:'/home'}} />
    // ) : (
    <div className="login-form"> {/* Apply CSS styles */}
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
        <label>Email:</label> 
        
        <input
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            validateInput('email', e.target.value);
          }}
          placeholder="Enter your email"
        />
        <span className="error">{errors.email}</span>
        </div>
        <div>
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            validateInput('password', e.target.value);
          }}
          placeholder="Enter your password"
        />
        <span className="error">{errors.password}</span>
        </div>
        <div>
          <button type="submit">Login</button>
        </div>
      </form>
    </div>
  )
  //);
}

export default LoginForm;




