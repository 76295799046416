
import React, { useState, useEffect } from 'react';
import Scanner from './Scanner';
import { Fab, TextareaAutosize, Paper } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import sound1 from './../barcode.wav';
import useSound from 'use-sound';
function BarcodeScanner() {
  const [results, setResults] = useState([]);
  const [scanning, setScanning] = useState(false);
  const [code, setCode] = useState('');
  const [play] = useSound(sound1);
  useEffect(() => {
   
    if (results.length > 0 && results[0].codeResult.code !== code) {
      play()
      setCode(results[0].codeResult.code);
    }
  }, [results, code]);

  const handleScan = () => {
    setScanning(!scanning);
    setResults([]);
  };

  const handleDetected = (result) => {
    setResults([result]);
  };

  return (
    <div className="scannerDiv">
      <Link to="/">
        <Fab style={{ marginRight: 20 }} color="secondary">
          <ArrowBack />
        </Fab>
      </Link>
      <span>
        <strong>Barcode Scanner</strong>
      </span>

      <Paper variant="outlined" style={{ marginTop: 30, width: 640, height: 320 }}>
        <Scanner onDetected={handleDetected} />
      </Paper>

      <TextareaAutosize
        style={{ fontSize: 32, width: 320, height: 100, marginTop: 30 }}
        rowsMax={4}
        defaultValue={'No data scanned'}
        value={results[0] ? results[0].codeResult.code : 'No data scanned'}
      />
      <TextareaAutosize
        style={{ fontSize: 32, width: 320, height: 100, marginTop: 30 }}
        rowsMax={4}
        defaultValue={'No data scanned'}
        value={results[0] ? results[0].codeResult.format : 'No data scanned'}
      />
    </div>
  );
}

export default BarcodeScanner;
