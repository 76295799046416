import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  HashRouter,
} from "react-router-dom";

import Home from "./pages/Home";
import Bargen from "./pages/BarcodeGenerator";
import Barscan from "./pages/BarcodeScanner";
import Qrgen from "./pages/qrcodeGenerator";
import Qrscan from "./pages/QrcodeScanner";

import { PrivateRoute, PublicRoute } from "./routeValudation";
import ImageScan from "./pages/imageScan";
import BarcodeReader from "./pages/2dbarcodereader"
//import LogIn from "./login/login";
import LoginForm from "./login/login";
import { createBrowserHistory } from "history";
// import SideBar from "./components/Sidebar/SideBar";
//import Header from "./components/header/header";
function App() {
  const history = createBrowserHistory();
  return (
    <div className="App">
      <div className="App-header">
      <HashRouter forceRefresh={true} history={history}>
          <div>
          {/* <SideBar></SideBar> */}
            {/* <Header></Header>  */}
            <Switch>
           
             
              <PrivateRoute
                path="/capture_image"
                component={ImageScan}
                exact
              />
              <PrivateRoute
                path="/barcode_scanner"
                component={Barscan}
                exact
              />
               <PrivateRoute
                path="/2d_barcode_scanner"
                component={BarcodeReader}
                exact
              />
              <PrivateRoute
                path="/qrcode_generator"
                component={Qrgen}
                exact
              />
              <PrivateRoute
                path="/qrcode_scanner"
                component={Qrscan}
                exact
              />
              <PrivateRoute
               path="/home" component={Home} exact />
              <PrivateRoute
                 path="/barcode_generator"
                 component={Bargen}
                 exact
              />
              <PublicRoute
                restricted={false}
                path="/"
                component={LoginForm}
                exact
              />
            </Switch>
          </div>
        </HashRouter>
      </div>
    </div>
  );
}

export default App;
