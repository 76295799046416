import React, {useEffect, useState} from 'react'
import {Fab, TextField, TextareaAutosize, Grid} from '@material-ui/core'
import {ArrowBack, GetApp,Print, Visibility} from '@material-ui/icons'
import { Link } from "react-router-dom";
import "./BarcodeGenerator.scss"
// var Barcode = require('react-barcode');
import print from 'ink-html'
import { useBarcode } from '@createnextapp/react-barcode'
import JsBarcode from 'jsbarcode';


function BarcodeGenerator() {
    const [barcode, setBarcode] = useState('123456789012');
    const handleChange = (event) => {
        let inputValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
        setBarcode(event.target.value ? inputValue : '');
    };
    const downloadBarcode = () => {
        const canvas = document.getElementById("barcode");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "mybarcode.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

 
    const [imgURL,setImgURL]=useState("")

useEffect(()=>{
    if(barcode !=""){
    JsBarcode("#barcode", barcode, {
        format: "code128",
        lineColor: "#000",
        width:4,
        height:100,
        displayValue: true
      });

    const canvas = document.getElementById("barcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    setImgURL(pngUrl)
    }
},[barcode])
    const printBarcode =()=>{
       
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write(document.getElementById("barcodeToPrint").innerHTML);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        setTimeout(()=>{
            mywindow.print();
            mywindow.close();
        },100)
    
        return true;
    }

    return (
      <div>
            <Link to="/">
            <Fab style={{marginRight:20}} color="secondary">
                <ArrowBack/>
            </Fab>
            </Link>
            <span><strong>Barcode Generator</strong></span>
            
            <div style={{marginTop:30, marginBottom:30}}>
                <TextField onChange={handleChange} style={{width:320}}
                value={barcode} label="Barcode input" size="large" variant="outlined" inputProps={{ maxlength: 12 }} error="" id="outlined-error"
                />
            </div>
            <Grid container>
            <Grid item sm={12} md={3}></Grid>
                <Grid item sm={12} md={6}>
            <div id="mybarcode">
                {
                    barcode !== ''
                    ?
                    <div id="barcodeToPrint">
                       <img src={imgURL}/>
                    <canvas id="barcode" />
                    </div>
                    :
                    <p>No barcode preview</p>
                }
            </div>
          
           
                {
                    barcode ? 
                   <div style={{textAlign:'center'}}>
                    <TextareaAutosize
                            style={{fontSize:18,width:"80%",height:100}}
                            rowsMax={4}
                            defaultValue={barcode}
                            value={barcode}
                        />
                    <div style={{marginTop:20}}>
                            <Fab onClick={downloadBarcode} color="secondary" id="elem">
                            <GetApp/>
                        </Fab>
                        <Fab onClick={(e,elem)=>printBarcode(e,elem)} style={{marginLeft:20}} color="secondary">
                            <Print/>
                        </Fab>
                    </div>
                    </div>:
                    ''
                }
                </Grid>
            </Grid>
      </div>
    );
  }
  
  export default BarcodeGenerator;
  